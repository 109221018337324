import React from 'react';
import Layout from '../../layouts/Layout';
import JobAlerts from '../../components/JobAlerts/JobAlerts';
import { graphql, useStaticQuery } from 'gatsby';
import JobTable from '../../components/JobTable/JobTable';

import heroImg from '../../images/JAG-Join-Our-Team-2.png';

const SearchPage = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);

	return (
		<Layout
			title={'JAG Careers - Opportunities'}
			desc={'JAG Careers - Opportunities'}
		>
			<section
				className={
					'flex min-h-[310px] flex-col items-center justify-center gap-6 bg-cover bg-top md:min-h-[65vh]'
				}
				style={{ backgroundImage: `url('${heroImg}')` }}
			>
				<h1 className="text-center font-permanent-marker text-[84px] text-white max-md:text-6xl">
					JOIN OUR TEAM
				</h1>
			</section>
			<JobTable data={data} />
			<JobAlerts />
		</Layout>
	);
};

export default SearchPage;
