import React, { useEffect, useState } from 'react';
import {
	Button,
	Container,
	Dropdown,
	Icon,
	Pagination,
	Search
} from 'semantic-ui-react';
import { insertGoogleMapAPIScript } from '../../utils/googleMaps';
import { queryJobs } from '../../utils/query';
import './JobList.css';
import { isValidUSZip } from '../../utils/validators';

let autoComplete;

// Job List Component
//      Props:
//          searchTargets -> ex ['title', 'city', 'state', 'reqNumber']
//          apiKey -> Google maps API key.
// 			indexes
//          options:
//              myLocationPlaceholderText
//              clearFieldIconTitle
//              searchFieldIconTitle
//              minLocationChangeTermCharacters
//              searchType
//              mapMarkerIconTitle
// 				buttonText
//
const JobList = (props) => {
	// States
	const [searchTerm, setSearchTerm] = useState('');
	const [places, setPlaces] = useState([]);

	const [searchTermText, setSearchTermText] = useState('');

	const [selectedStates, setSelectedStates] = useState([]);
	const [selectedCities, setSelectedCities] = useState([]);
	const [cities, setCities] = useState([]);
	const [states, setStates] = useState([]);
	const [displayedCities, setDisplayedCities] = useState([]);

	const [selectedShiftTypes, setSelectedShiftTypes] = useState([]);
	const [shiftTypes, setShiftTypes] = useState([]);

	const [selectedCategories, setSelectedCategories] = useState([]);
	const [categories, setCategories] = useState([]);

	const [selectedTitles, setSelectedTitles] = useState([]);
	const [titles, setTitles] = useState([]);

	const [sortDirection, setSortDirection] = useState('desc');

	const [locationTerm, setLocationTerm] = useState(''); //
	const [locationTermText, setLocationTermText] = useState('');

	const [jobs, setJobs] = useState([]); // All jobs.
	const [displayedJobs, setDisplayedJobs] = useState([]); // Filtered Jobs that are displayed.
	const [disableLocationSearch, setDisableLocationSearch] = useState(false);
	const [lat, setLat] = useState('');
	const [lng, setLng] = useState('');
	const [latTemp, setLatTemp] = useState('');
	const [lngTemp, setLngTemp] = useState('');
	const [range, setRange] = useState(10);
	const [rangeTemp, setRangeTemp] = useState(10);
	const [units, setUnits] = useState('mi'); //Todo: Make a KM/Miles Switcher
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [locationResultSelected, setLocationResultSelected] = useState(false);

	const [noResultsMessage, setNoResultsMessage] =
		useState('No Results Found.');

	// Variables
	const data = props.data;
	const indexes = props.indexes || [
		{ name: 'title', factor: 4 },
		{ name: 'description', factor: 1 },
		{ name: 'city', factor: 2 },
		{ name: 'state', factor: 2 },
		{ name: 'reqNumber', factor: 6 },
		{ name: 'category', factor: 3 }
	];

	// Hooks
	useEffect(() => {
		// Extract jobs from GraphQL Query
		let tempJobs = [];
		let allJobs = data?.allSitePage?.edges;
		let tempShifts = [...shiftTypes];
		let tempCities = [];
		let tempStates = [];
		let tempCategories = [];
		let tempTitles = [];
		for (let job in allJobs) {
			if (allJobs[job]?.node?.pageContext) {
				if (allJobs[job]?.node?.pageContext?.title) {
					const fullTime = String(
						allJobs[job]?.node?.pageContext?.fullTime
					);
					if (
						fullTime &&
						fullTime !== 'n/a' &&
						fullTime !== 'undefined'
					) {
						if (!tempShifts.some((e) => e.key === fullTime)) {
							tempShifts.push({
								key: fullTime,
								text: fullTime,
								value: fullTime
							});
						}
					}

					const category = String(
						allJobs[job]?.node?.pageContext?.category
					);
					if (
						category &&
						category !== 'n/a' &&
						category !== 'undefined'
					) {
						if (!tempCategories.some((e) => e.key === category)) {
							tempCategories.push({
								key: category,
								text: category,
								value: category
							});
						}
					}
					const title = String(
						allJobs[job]?.node?.pageContext?.title
					);
					if (title && title !== 'n/a' && title !== 'undefined') {
						if (!tempTitles.some((e) => e.key === title)) {
							tempTitles.push({
								key: title,
								text: title,
								value: title
							});
						}
					}
					tempTitles.sort((a, b) => a.key.localeCompare(b.key));
					const city = allJobs[job]?.node?.pageContext?.city;
					const state = allJobs[job]?.node?.pageContext?.state;
					const fullStateName =
						allJobs[job]?.node?.pageContext?.fullStateName;

					if (city && city !== 'n/a' && state && state !== 'n/a') {
						if (!tempCities.some((e) => e.key === city)) {
							tempCities.push({
								key: city,
								text: `${city}, ${state}`,
								value: city,
								state: state
							});
						}
						if (!tempStates.some((e) => e.key === state)) {
							tempStates.push({
								key: state,
								text: `${
									fullStateName ? fullStateName : state
								}`,
								value: state,
								state: state
							});
						}
					}
					tempJobs.push(allJobs[job]?.node?.pageContext);
				}
			}
		}

		setShiftTypes(tempShifts);
		setCities(tempCities);
		setDisplayedCities(tempCities);
		setStates(tempStates);
		setCategories(tempCategories);
		setTitles(tempTitles);
		// Set the jobs.
		setJobs(tempJobs);
		setDisplayedJobs(tempJobs);
	}, [data]);

	useEffect(() => {
		// Add google API in head.
		insertGoogleMapAPIScript(
			`https://maps.googleapis.com/maps/api/js?key=${props.apiKey}&libraries=places&callback=Function.prototype`,
			() => {
				autoComplete =
					new window.google.maps.places.AutocompleteService();
			}
		);
	}, [props.apiKey]);

	useEffect(() => {
		// Get search parameters from URL.
		const url = new URL(window.location.href);
		const term = url.searchParams.get('term');
		const latParam = url.searchParams.get('a');
		const stateParam = url.searchParams.get('state');
		const lngParam = url.searchParams.get('b');
		const o = url.searchParams.get('o');
		const loc = url.searchParams.get('loc');
		if (stateParam) {
			setSelectedStates([stateParam]);
		}
		if (term) {
			setSearchTerm(term);
			setSearchTermText(term);
			setSearchTermIcon(
				<Icon
					name="close"
					onClick={handleClearSearchTermClick}
					title={props?.option?.clearFieldIconTitle || 'Clear field.'}
					link
				/>
			);
		}
		if (latParam && lngParam) {
			setLat(latParam);
			setLng(lngParam);
			// If using user location from browser.
			if (o === 'uloc') {
				setLocationTerm(
					props?.options?.myLocationPlaceholderText || 'My Location'
				);
				setLocationTermText(
					props?.options?.myLocationPlaceholderText || 'My Location'
				);
				setDisableLocationSearch(true);
				setDisableLocationSearch(true);
				setLocationIcon(
					<Icon
						name="close"
						onClick={handleClearMyLocationTermClick}
						title={
							props?.option?.clearFieldIconTitle || 'Clear field'
						}
						link
					/>
				);
			} else if (o === 'gloc' && loc) {
				// If using Google maps location.
				setLocationTerm(loc);
				setLocationTermText(loc);
				setDisableLocationSearch(true);

				setLocationIcon(
					<Icon
						name="close"
						onClick={handleClearMyLocationTermClick}
						title={
							props?.option?.clearFieldIconTitle || 'Clear field'
						}
						link
					/>
				);
				const retrievedPLaces = localStorage.getItem('places');
				if (retrievedPLaces) {
					setPlaces(JSON.parse(retrievedPLaces));
				}
			}
		}
	}, []);

	useEffect(() => {
		setPage(1);
		// Query jobs that match the selected filters and terms.
		const queriedJobs = queryJobs(
			jobs,
			searchTerm,
			lat,
			lng,
			range,
			indexes,
			units,
			selectedStates,
			selectedShiftTypes,
			selectedCities,
			selectedCategories,
			selectedTitles
		);
		setDisplayedJobs(queriedJobs);
	}, [
		searchTerm,
		lat,
		lng,
		range,
		units,
		selectedStates,
		selectedShiftTypes,
		jobs,
		selectedCities,
		selectedCategories,
		selectedTitles
	]);

	useEffect(() => {
		let tempCities = [];
		selectedStates.map((state) => {
			cities.map((city) => {
				if (city?.state === state) {
					tempCities.push(city);
				}
			});
		});
		setDisplayedCities(tempCities);
	}, [selectedStates]);

	const handleClearSearchTermClick = () => {
		setSearchTerm('');
		setSearchTermText('');
		setSearchTermIcon(<></>);
	};
	const handleSearchTermChange = (e) => {
		const value = e.target.value;
		setSearchTermText(value);
		if (value.length > 0) {
			setSearchTermIcon(
				<Icon
					name="close"
					onClick={handleClearSearchTermClick}
					title={props?.option?.clearFieldIconTitle || 'Clear field.'}
					link
				/>
			);
		} else {
			setSearchTermIcon(
				<Icon
					name="search"
					title={props?.option?.searchFieldIconTitle || 'Search.'}
				/>
			);
		}
	};
	const onStateDropdownChangeHandler = (e, data) => {
		setSelectedStates(data.value);
		if (data.value.length === 0) {
			setSelectedCities([]);
		}
	};
	const onCitiesDropdownChangeHandler = (e, data) => {
		setSelectedCities(data.value);
	};
	const onShiftTypeDropdownChangeHandler = (e, data) => {
		setSelectedShiftTypes(data.value);
	};
	const onCategoriesDropdownChangeHandler = (e, data) => {
		setSelectedCategories(data.value);
	};
	const onTitlesDropdownChangeHandler = (e, data) => {
		setSelectedTitles(data.value);
	};
	const handleLocationTermChange = (e) => {
		setLocationTermText(e.target.value);
		if (
			e.target.value.length >
				props?.options?.minLocationChangeTermCharacters ||
			3
		) {
			try {
				autoComplete
					.getPlacePredictions(
						{
							input: e.target.value,
							types: props?.option?.searchType || ['(cities)'],
							componentRestrictions: {
								country: props?.option?.country || 'us'
							}
						},
						(places) => {
							for (let place in places) {
								places[place].title = places[place].description;
								places[place].description = '';
							}
							setPlaces(places);
						}
					)
					.catch((e) => {
						console.log('Geocode error: ', e);
					});
			} catch (e) {
				console.log('Geocode error: ', e);
				setNoResultsMessage('Geocode Error.');
			}
		} else {
			setPlaces([]);
		}
		if (e.target.value.length > 0) {
			setLocationIcon(
				<Icon
					name="close"
					onClick={handleClearMyLocationTermClick}
					title={props?.option?.clearFieldIconTitle || 'Clear field.'}
					link
				/>
			);
		} else {
			setLocationIcon(
				<Icon
					name="map marker alternate"
					onClick={handleLocateMeClick}
					title={
						props?.options?.mapMarkerIconTitle || 'Use my location'
					}
					link
				/>
			);
		}
	};

	const handleSortChange = () => {
		setSortDirection(!sortDirection);
	};
	const SortDescAsc = () => {
		return sortDirection ? (
			<svg
				aria-sort={'ascending'}
				xmlns="http://www.w3.org/2000/svg"
				onClick={handleSortChange}
				style={{ width: 20, cursor: 'pointer' }}
				viewBox="0 0 24 24"
				fill="currentColor"
				className="h-6 w-6"
			>
				<path
					fillRule="evenodd"
					d="M2.25 4.5A.75.75 0 013 3.75h14.25a.75.75 0 010 1.5H3a.75.75 0 01-.75-.75zm14.47 3.97a.75.75 0 011.06 0l3.75 3.75a.75.75 0 11-1.06 1.06L18 10.81V21a.75.75 0 01-1.5 0V10.81l-2.47 2.47a.75.75 0 11-1.06-1.06l3.75-3.75zM2.25 9A.75.75 0 013 8.25h9.75a.75.75 0 010 1.5H3A.75.75 0 012.25 9zm0 4.5a.75.75 0 01.75-.75h5.25a.75.75 0 010 1.5H3a.75.75 0 01-.75-.75z"
					clipRule="evenodd"
				/>
			</svg>
		) : (
			<svg
				aria-sort={'descending'}
				style={{ width: 20, cursor: 'pointer' }}
				onClick={handleSortChange}
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				strokeWidth={1.5}
				stroke="currentColor"
				className="h-6 w-6"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0l-3.75-3.75M17.25 21L21 17.25"
				/>
			</svg>
		);
	};

	const handleRangeChange = (e, data) => {
		setRangeTemp(data.value);
	};
	const handleRowsPerPageChange = (e, data) => {
		setRowsPerPage(data.value);
	};
	const handleResultSelect = (e, data) => {
		setLocationTermText(data.result.title);

		setLocationResultSelected(true);
		setDisableLocationSearch(true);
		let geocoder = new window.google.maps.Geocoder();
		geocoder.geocode(
			{
				placeId: data.result.place_id
			},
			(res, status) => {
				if (status === 'OK') {
					setLatTemp(res[0]?.geometry?.location?.lat());
					setLngTemp(res[0]?.geometry?.location?.lng());
				}
			}
		);
	};
	const handleClearMyLocationTermClick = () => {
		setLocationTerm('');
		setLocationTermText('');
		setLocationResultSelected(false);
		setDisableLocationSearch(false);
		setLocationIcon(
			<Icon
				name="map marker alternate"
				onClick={handleLocateMeClick}
				title={props?.options?.mapMarkerIconTitle || 'Use my location'}
				link
			/>
		);
		setLat('');
		setLng('');
		setLatTemp('');
		setLngTemp('');
	};
	const handlePaginationChange = (e, { activePage }) => {
		setPage(activePage);
		const list = document.getElementById('JobListSection');

		list?.scrollIntoView({
			behavior: 'smooth'
		});
	};
	const handleLocateMeClick = (e) => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				function setCoordinates(position) {
					setLat(position.coords.latitude.toString());
					setLng(position.coords.longitude.toString());
					setLocationTermText(
						props?.options?.myLocationPlaceholderText ||
							'My Location'
					);
					setDisableLocationSearch(true);
					setLocationIcon(
						<Icon
							name="close"
							onClick={handleClearMyLocationTermClick}
							title={
								props?.option?.clearFieldIconTitle ||
								'Clear field'
							}
							link
						/>
					);
				},
				(e) => {}
			);
		}
	};
	const [locationIcon, setLocationIcon] = useState(
		<Icon
			name="map marker alternate"
			onClick={handleLocateMeClick}
			title={props?.options?.mapMarkerIconTitle || 'Use my location'}
			link
		/>
	);
	const [searchTermIcon, setSearchTermIcon] = useState(
		<Icon
			name="search"
			title={props?.option?.clearFieldIconTitle || 'Search.'}
		/>
	);

	const rangeOptions = [
		{ key: 1, text: '1 Mile', value: 1 },
		{
			key: 10,
			text: '10 Miles',
			value: 10
		},
		{ key: 25, text: '25 Miles', value: 25 },
		{
			key: 50,
			text: '50 Miles',
			value: 50
		},
		{ key: 100, text: '100 Miles', value: 100 },
		{ key: 500, text: '500+ Miles', value: 1000000000 }
	];
	const rowsPerPageOptions = [
		{ key: 10, text: '10', value: 10 },
		{ key: 25, text: '25', value: 25 },
		{
			key: 50,
			text: '50',
			value: 50
		},
		{ key: 100, text: '100', value: 100 },
		{ key: 250, text: '250', value: 250 },
		{
			key: 'All',
			text: 'All',
			value: 1021231231330
		}
	];

	function descendingComparator(a, b, orderBy) {
		if (orderBy === 'distance') {
			if (b[orderBy] < a[orderBy]) {
				return -1;
			}
			if (b[orderBy] > a[orderBy]) {
				return 1;
			}
		} else if (orderBy === 'weight') {
			if (b[orderBy] < a[orderBy]) {
				return -1;
			}
			if (b[orderBy] > a[orderBy]) {
				return 1;
			}
		} else {
			if (Date.parse(b[orderBy]) < Date.parse(a[orderBy])) {
				return -1;
			}
			if (Date.parse(b[orderBy]) > Date.parse(a[orderBy])) {
				return 1;
			}
		}

		return 0;
	}

	function getComparator(order, orderBy) {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

	return (
		<div className={props?.options?.jobListClassNames || ''}>
			<div className={props?.options?.searchBarWrapperClassNames || ''}>
				<Container
					as={'form'}
					style={{ display: 'flex' }}
					className={props?.options?.searchBarClassNames || ''}
					onSubmit={(e) => {
						e.preventDefault();
						setSearchTerm(searchTermText);
						setLat(latTemp);
						// setSortDirection(false);
						setRange(rangeTemp);
						setLng(lngTemp);
						window.history.replaceState(
							null,
							null,
							'?term=' + searchTermText
						);
					}}
				>
					<Search
						showNoResults={true}
						noResultsMessage={''}
						onSearchChange={handleSearchTermChange}
						className={props?.options?.searchTermClassNames || ''}
						icon={searchTermIcon}
						minCharacters={32143124123412}
						name="search"
						id="search_field"
						value={searchTermText}
						placeholder={'Job Title, Keywords, Req ID'}
					/>
					<Search
						results={places}
						onSearchChange={handleLocationTermChange}
						onResultSelect={handleResultSelect}
						id={'location_field'}
						className={
							props?.options?.formLocationTermClassNames || ''
						}
						minCharacters={
							props?.options?.minLocationTermCharacters || 4
						}
						icon={locationIcon}
						noResultsMessage={noResultsMessage}
						label={''}
						name={'location'}
						value={locationTermText}
						disabled={disableLocationSearch}
						placeholder={'Location'}
					/>
					<Dropdown
						placeholder="Miles"
						defaultValue={10}
						onChange={handleRangeChange}
						value={rangeTemp}
						selection
						options={rangeOptions}
					/>
					<Button className={''} type={'submit'}>
						Search
					</Button>
				</Container>
			</div>

			<div className={props?.options?.jobTableWrapperClassNames || ''}>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					{/* Search Results*/}
					<Container
						className={
							props?.options?.jobResultsTitleClassNames || ''
						}
					>
						<span className={'search-results-text'}>
							{props?.searchResultsText || 'Search Results'}{' '}
							<span className={'location-results-text'}>
								{locationTerm || lat
									? ` - ${locationTermText}`
									: null}
							</span>
						</span>
					</Container>

					{/* Job List */}
					<Container>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								flexWrap: 'wrap'
							}}
						>
							{/* Filters */}
							<div
								job-filters
								className={
									'jobFilters ' +
										props?.options?.filtersClassNames || ''
								}
							>
								{!props?.hideFilters ? (
									<div
										className={'filterWrapper'}
										style={{
											display: 'flex',
											flexDirection: 'column',
											gap: '10px',
											padding: '10px 30px 10px 0px'
										}}
									>
										<span
											className={
												props?.options
													?.filtersTextClassName || ''
											}
										>
											{props?.filtersTextFilters ||
												'Filters'}
											:{' '}
										</span>
										<Dropdown
											style={{ width: '100%' }}
											onChange={
												onStateDropdownChangeHandler
											}
											value={selectedStates}
											placeholder="State"
											fluid
											clearable
											multiple
											search
											selection
											options={states}
										/>
										<Dropdown
											style={{ width: '100%' }}
											onChange={
												onCitiesDropdownChangeHandler
											}
											value={selectedCities}
											placeholder="City"
											fluid
											clearable
											multiple
											disabled={
												selectedStates.length === 0 ||
												displayedJobs.length === 0
											}
											search
											selection
											options={displayedCities}
										/>
										{categories.length > 0 ? (
											<Dropdown
												onChange={
													onCategoriesDropdownChangeHandler
												}
												value={selectedCategories}
												placeholder="Category"
												fluid
												clearable
												search
												style={{ width: '100%' }}
												selection
												multiple
												options={categories}
											/>
										) : null}

										{shiftTypes.length > 0 ? (
											<Dropdown
												onChange={
													onShiftTypeDropdownChangeHandler
												}
												value={selectedShiftTypes}
												placeholder="Shift"
												fluid
												clearable
												search
												style={{ width: '100%' }}
												selection
												multiple
												options={shiftTypes}
											/>
										) : null}
										{titles.length > 0 ? (
											<Dropdown
												onChange={
													onTitlesDropdownChangeHandler
												}
												value={selectedTitles}
												placeholder="Title"
												fluid
												clearable
												search
												style={{ width: '100%' }}
												selection
												multiple
												options={titles}
											/>
										) : null}
									</div>
								) : (
									<></>
								)}
							</div>
							<div
								className={
									'jobList ' +
										props?.options?.jobListClassNames || ''
								}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										marginTop: -30,
										marginBottom: -20
									}}
								>
									<span>Jobs: {displayedJobs.length}</span>
									<span title="Sort by Posting Date">
										<SortDescAsc />
									</span>
								</div>
								{displayedJobs.length === 0 ? (
									<h3>No Results Found.</h3>
								) : (
									''
								)}
								{displayedJobs
									.sort(
										getComparator(
											sortDirection ? 'desc' : 'asc',
											props?.orderByKey || 'startDate'
										)
									)
									.slice(
										(page - 1) * rowsPerPage,
										(page - 1) * rowsPerPage + rowsPerPage
									)
									.map((job) => {
										return (
											<div className={'job'}>
												<div className={'desc'}>
													<a
														href={job.jobPath}
														target={
															props.targetBlank
																? '_blank'
																: null
														}
													>
														<span
															className={
																'position'
															}
														>
															{job.title}
														</span>
														{/*<span className={'tiret'}> - </span>*/}
														<br />
														{job.fullTime &&
														job.fullTime !==
															'n/a' ? (
															<>
																<span
																	className={
																		'shift'
																	}
																>
																	{
																		job.fullTime
																	}
																</span>
																{job.city ===
																	'.' ||
																!job.city ||
																job.city ===
																	'n/a' ? null : (
																	<span
																		className={
																			'tiret'
																		}
																	>
																		{' '}
																		-{' '}
																	</span>
																)}{' '}
															</>
														) : null}
														<span
															className={
																'location'
															}
														>
															{job.city === '.' ||
															!job.city ||
															job.city === 'n/a'
																? null
																: `${job.city},`}{' '}
															{job.state}
														</span>
													</a>
													{job.startDate !== 'n/a' ? (
														<span
															className={'posted'}
														>
															<b>
																Posted:{' '}
																<span
																	className={
																		'date'
																	}
																>
																	{new Date(
																		Date.parse(
																			job.startDate
																		)
																	).toDateString()}
																</span>
															</b>
														</span>
													) : null}
												</div>
												<div className={'apply'}>
													<ul>
														{props.applyNowValues
															? props.applyNowValues.map(
																	(value) => {
																		return (
																			<>
																				{value.hideLabel ? null : (
																					<span
																						className={`${value.value.toLowerCase()}-label`}
																					>
																						{
																							value.text
																						}
																					</span>
																				)}
																				<li
																					className={`${value.value.toLowerCase()}-value`}
																				>
																					{
																						job[
																							value
																								.value
																						]
																					}
																				</li>
																			</>
																		);
																	}
															  )
															: null}
													</ul>
													<a
														href={job.jobPath}
														target={
															props.targetBlank
																? '_blank'
																: null
														}
													>
														<button>
															{props?.options
																?.buttonText ||
																'APPLY NOW'}
														</button>
													</a>
												</div>
											</div>
										);
									})}
							</div>
						</div>
						<div className={'pagination'}>
							<Pagination
								activePage={page}
								onPageChange={handlePaginationChange}
								totalPages={
									rowsPerPage >= displayedJobs.length
										? 1
										: Math.ceil(
												displayedJobs.length /
													rowsPerPage
										  )
								}
							/>
						</div>
					</Container>
				</div>
			</div>
		</div>
	);
};

export default JobList;
