import { removeAccents } from './stringFunctions';
import { distance } from './math';

const queryJobs = (
	jobs,
	term,
	latitude,
	longitude,
	range,
	indexes,
	units,
	states,
	shiftTypes,
	cities,
	categories,
	titles
) => {
	let tempList = [];

	// If search term is empty, return all jobs.

	// Lowercase the term.
	term = term.toLowerCase();

	// Remove accents.
	term = removeAccents(term);

	let allJobs = [...jobs];
	if (
		term === '' &&
		!latitude &&
		!longitude &&
		states.length === 0 &&
		cities.length === 0 &&
		shiftTypes.length === 0 &&
		categories.length === 0 &&
		titles.length === 0
	) {
		console.log('Categories: ', categories);
		return allJobs;
	}

	// Replace punctuating between words with spaces.
	term = term.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, ' ');

	// Split search words, delimited by spaces
	let terms = term.split(' ');

	// Remove any terms that are empty strings. ""
	terms = terms.filter((e) => e !== '');
	for (let job in allJobs) {
		if (allJobs.hasOwnProperty(job)) {
			// If lat/lng is given, check if the job is close enough to show.
			if (latitude && longitude) {
				const userLat = parseFloat(latitude);
				const userLng = parseFloat(longitude);
				if (userLat && userLng) {
					if (allJobs[job]?.lat && allJobs[job]?.lng) {
						const jobLat = parseFloat(allJobs[job]?.lat);
						const jobLng = parseFloat(allJobs[job]?.lng);
						if (!jobLat || !jobLng) continue;
						const dist = distance(
							userLat,
							userLng,
							jobLat,
							jobLng,
							units
						);
						allJobs[job].distance = dist;
						if (dist > range) {
							continue;
						}
					}
				}
			}
			if (states.length > 0) {
				let addJob = false;
				for (let state in states) {
					if (String(allJobs[job]?.state) === states[state])
						addJob = true;
				}
				if (!addJob) continue;
			}
			if (shiftTypes.length > 0) {
				let addJob = false;
				for (let shift in shiftTypes) {
					if (String(allJobs[job]?.fullTime) === shiftTypes[shift])
						addJob = true;
				}
				if (!addJob) continue;
			}
			if (categories.length > 0) {
				let addJob = false;
				for (let category in categories) {
					if (String(allJobs[job]?.category) === categories[category])
						addJob = true;
				}
				if (!addJob) continue;
			}
			if (titles.length > 0) {
				let addJob = false;
				for (let title in titles) {
					if (String(allJobs[job]?.title) === titles[title])
						addJob = true;
				}
				if (!addJob) continue;
			}
			if (cities.length > 0) {
				let addJob = false;
				for (let city in cities) {
					if (String(allJobs[job]?.city) === cities[city])
						addJob = true;
				}
				if (!addJob) continue;
			}

			let indexWeight = 0;
			let data = jobs[job];

			// For each index
			for (let index in indexes) {
				if (indexes.hasOwnProperty(index)) {
					// Check if the jobData has that index.
					if (data.hasOwnProperty(indexes[index].name)) {
						// Make sure all terms are included in that index.
						// let allTermsIncluded = false;

						// For each search term
						for (let _term in terms) {
							if (terms.hasOwnProperty(_term)) {
								// If the index includes the term.
								if (
									data[indexes[index].name]
										.toLowerCase()
										.includes(terms[_term])
								) {
									indexWeight =
										indexWeight +
										100 * indexes[index].factor;
								}
							}
						}
					}
				}
			}
			if (indexWeight > 0) {
				data.weight = indexWeight;
				tempList.push(data);
			} else if (term === '') {
				tempList.push(data);
			}
		}
	}
	return tempList;
};
export { queryJobs };
