import * as React from 'react';
// @ts-ignore
import JobList from '../career-page-components/components/JobList/JobList';
import './JobTable.css';

interface JobTableProps {
	data: any;
}
const JobTable: React.FC<JobTableProps> = (props: JobTableProps) => {
	const jobListOptions = {
		jobListClassNames: 'job-list',
		searchBarWrapperClassNames: 'search-bar-wrapper',
		searchBarClassNames: 'search-bar',
		jobResultsTitleWrapperClassNames: 'results-wrapper',
		jobResultsTitleClassNames: 'search-results',
		jobListWrapperClassNames: 'job-list-wrapper',
		buttonText: 'Apply Now'
	};
	return (
		<section className={'JobListSection'} id="JobListSection">
			<JobList
				data={props.data}
				options={jobListOptions}
				searchTargets={[
					'title',
					'city',
					'reqNumber',
					'category',
					'state'
				]}
				orderByKey="postingDate"
				apiKey={'AIzaSyBI3gu5lHZZgnfDIbqMBIr8ThlVccXTDq0'}
			/>
		</section>
	);
};

export default JobTable;
