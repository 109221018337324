/**
 * Replace all occurrences of a string with another one.
 * @param haystack
 * @param needle
 * @param replacement
 * @returns {*}
 */
function replaceAll( haystack, needle, replacement ) {
    while (haystack.indexOf( needle ) >= 0) {
        haystack = haystack.replace( needle, replacement );
    }
    return haystack
}

/**
 * Remove any weird characters from the url. Lowercase.
 * @param url
 * @returns {*}
 */
function sanitizeURL( url ) {
    url = url.toString().toLowerCase();
    url = removeAccents( url );
    url = replaceAll( url, '-/ ', '-' )
    url = replaceAll( url, '   ', '-' )
    url = replaceAll( url, '  ', '-' )
    url = replaceAll( url, ' ', '-' )
    url = replaceAll( url, ', ', '-' )
    url = replaceAll( url, ',', '-' )
    url = replaceAll( url, '(', '' )
    url = replaceAll( url, '(', '' )
    url = replaceAll( url, ')', '' )
    url = url.replace( /[^a-z0-9-_]/gi, '' );
    url = replaceAll( url, '----', '-' )
    url = replaceAll( url, '---', '-' )
    url = replaceAll( url, '--', '-' )
    return url;
}

/**
 * Remove Accents from a string.
 * @param str
 * @returns {string}
 */
const removeAccents = ( str ) => {
    const accents = {
        a: 'àáâãäåæ', c: 'ç', e: 'èéêëæ', i: 'ìíîï', n: 'ñ', o: 'òóôõöø', s: 'ß', u: 'ùúûü', y: 'ÿ'
    }
    let newStr = '';
    for (let char in str) {
        let continueFlag = false;
        for (let accent in accents) {
            if (accents[accent].includes( str[char] )) {
                newStr += accent;
                continueFlag = true;
                break;
            }
        }
        if (continueFlag) continue;
        newStr += str[char];
    }
    return newStr;
};

export {
    removeAccents, replaceAll, sanitizeURL
};
